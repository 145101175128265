import React from 'react';
import './style.scss';

interface Props {
  children: React.ReactNode;
}

export const UserList = ({ children }: Props) => (
  <ul className='users-list'>{children}</ul>
);
