import React, { useMemo, useState } from 'react';
import './style.scss';
import { APIPreUser } from '../../../../../../shared/interfaces';
import { GeneralScreen } from '../general/screen';
import { Button } from '../../../general/button';
import { UserList } from '../general/userList';
import { transformAddress } from '../../../layout/header/walletAddress';
import { UserScreenHeader } from '../general/header';
import { fetcher } from '../../../../utils/fetcher';
import useAlert from '../../../../contexts/alert';

interface Props {
  users: APIPreUser[];
  loading: boolean;
  update: () => any;
}

export const WithNftScreen = ({ loading, users, update }: Props) => {
  const alert = useAlert();
  const [inviteLoading, setInviteLoading] = useState(false);

  const handleInviteClick = () => {
    setInviteLoading(true);
    fetcher.admin
      .inviteUsers()
      .then((response) => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(response.inviteLinks))
        );
        element.setAttribute('download', 'InviteLinks.json');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        update();
      })
      .catch((err) => {
        alert.showError(err);
        console.log(err);
      })
      .finally(() => setInviteLoading(false));
  };

  return (
    <GeneralScreen loading={loading}>
      <UserScreenHeader>
        <Button
          loading={inviteLoading}
          onClick={handleInviteClick}
          disabled={users.length === 0}
        >
          Generate Invites (unreversible)
        </Button>
      </UserScreenHeader>
      <UserList>
        {users.map((user, index) => (
          <User data={user} key={index} />
        ))}
      </UserList>
    </GeneralScreen>
  );
};

interface UserProps {
  data: APIPreUser;
}

const User = ({ data }: UserProps) => {
  const transformedAddress = useMemo<string>(() => {
    return transformAddress(data.address, 32);
  }, [data.address]);

  return (
    <li className='user-withNft'>
      <a href={`${process.env.OPENSEA_URL}${data.address}`} target='_blank'>
        <p>{transformedAddress}</p>
      </a>
    </li>
  );
};
