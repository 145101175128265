import React from 'react';
import './style.scss';

interface Props {
  color: 'empty' | 'red' | 'yellow' | 'green';
}

export const UserIndicator = ({ color }: Props) => (
  <div className={`user-indicator-${color}`} />
);
