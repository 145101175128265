import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetcher } from '../utils/fetcher';
import localStorage from '../utils/localStorage';

interface IAuth {
  loading: boolean;
  loggedIn: boolean;
  login: (password: string) => Promise<void>;
  logout: () => void;
}

// @ts-ignore
const AdminAuthContext = createContext<IAuth>();

interface Props {
  children: React.ReactNode;
}

export const AdminAuthContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const existing = localStorage.getToken();
    if (!existing) {
      setLoading(false);
    } else {
      validateToken(existing).then(() => setLoading(false));
    }
  }, []);

  const login = (password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      fetcher.admin
        .login(password)
        .then((response) => {
          localStorage.clear();
          localStorage.setToken(response.token);
          fetcher.admin.setTokenHeader(response.token);
          setLoggedIn(true);
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  const validateToken = (token: string): Promise<void> => {
    return new Promise((resolve) => {
      fetcher.admin.setTokenHeader(token);
      fetcher.admin
        .validateToken()
        .then(() => setLoggedIn(true))
        .catch((err) => {
          console.log(err);
          localStorage.clear();
          fetcher.admin.clearHeaders();
        })
        .finally(() => resolve());
    });
  };

  const logout = () => {
    fetcher.admin.clearHeaders();
    localStorage.clear();
    setLoggedIn(false);
  };

  return (
    <AdminAuthContext.Provider
      value={{
        loading,
        loggedIn,
        login,
        logout,
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

const useAdminAuth = () => useContext(AdminAuthContext);

export default useAdminAuth;
