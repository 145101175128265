import React, { useEffect, useState } from 'react';
import './style.scss';
import clsx from 'clsx';
import { CreateUser } from '../screens/createUser';
import { fetcher } from '../../../utils/fetcher';
import { APIPreUser, APIUser } from '../../../../../shared/interfaces';
import { InitScreen } from '../screens/init';
import { WithNftScreen } from '../screens/withNft';
import { InvitedScreen } from '../screens/invited';
import { RsvpScreen } from '../screens/rsvp';
import { Button } from '../../general/button';
import useAlert from '../../../contexts/alert';
import { useSearchParams } from 'react-router-dom';

enum Screens {
  CREATE_USER = '+++',
  INIT = 'Initial',
  WITH_NFT = 'With NFT',
  INVITE_SENT = 'Invite Sent',
  RSVP = 'Filled RSVP',
}

export const AdminLoggedIn = () => {
  const [params, setParams] = useSearchParams();
  const alert = useAlert();
  const [screen, setScreen] = useState<Screens>(Screens.INIT);
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState<APIPreUser[]>([]);
  const [withNft, setWithNft] = useState<APIPreUser[]>([]);
  const [invited, setInvited] = useState<APIPreUser[]>([]);
  const [users, setUsers] = useState<APIUser[]>([]);

  useEffect(() => {
    updateUsers();
  }, []);

  useEffect(() => {
    const page = params.get('page');
    if (page) {
      const screen = getScreenByUrl(page);
      if (screen) {
        setScreen(screen);
      }
    }
  }, [params]);

  const getUrlByScreen = (screen: Screens): string => {
    switch (screen) {
      case Screens.CREATE_USER:
        return 'create_users';
      case Screens.WITH_NFT:
        return 'with_nft';
      case Screens.INVITE_SENT:
        return 'invite_sent';
      case Screens.RSVP:
        return 'rsvp';
      case Screens.INIT:
        return 'init';
    }
  };

  const handleScreenChange = (newScreen: Screens) => {
    setParams((prev) => {
      prev.set('page', getUrlByScreen(newScreen));
      return prev;
    });
  };

  const getScreenByUrl = (url: string): Screens | undefined => {
    switch (url) {
      case 'create_users':
        return Screens.CREATE_USER;
      case 'with_nft':
        return Screens.WITH_NFT;
      case 'invite_sent':
        return Screens.INVITE_SENT;
      case 'rsvp':
        return Screens.RSVP;
      default:
        return Screens.INIT;
    }
  };

  const updateUsers = () => {
    setLoading(true);
    fetcher.admin
      .getAllUsers()
      .then((data) => {
        setInit(data.init);
        setWithNft(data.withNft);
        setInvited(data.invited);
        setUsers(data.users);
      })
      .catch((err) => alert.showError(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className='navigation'>
        <Link
          screen={Screens.INIT}
          activeScreen={screen}
          setActiveScreen={handleScreenChange}
          count={init.length}
        />
        <Link
          screen={Screens.WITH_NFT}
          activeScreen={screen}
          setActiveScreen={handleScreenChange}
          count={withNft.length}
        />
        <Link
          screen={Screens.INVITE_SENT}
          activeScreen={screen}
          setActiveScreen={handleScreenChange}
          count={invited.length}
        />
        <Link
          screen={Screens.RSVP}
          activeScreen={screen}
          setActiveScreen={handleScreenChange}
          count={users.length}
        />
        <Link
          screen={Screens.CREATE_USER}
          activeScreen={screen}
          setActiveScreen={handleScreenChange}
        />
        <Button onClick={updateUsers} loading={loading}>
          Refresh
        </Button>
      </div>
      <div className='content'>
        <Content
          loading={loading}
          update={updateUsers}
          screen={screen}
          init={init}
          invited={invited}
          users={users}
          withNft={withNft}
        />
      </div>
    </>
  );
};

interface LinkProps {
  screen: Screens;
  activeScreen: Screens;
  setActiveScreen: (s: Screens) => void;
  count?: number;
}

const Link = ({ screen, activeScreen, setActiveScreen, count }: LinkProps) => (
  <div
    className={clsx('nav-link', screen === activeScreen && 'nav-link-active')}
    onClick={() => setActiveScreen(screen)}
  >
    {screen}
    {count && count > 0 ? ` (${count})` : null}
  </div>
);

interface ContentProps {
  loading: boolean;
  update: () => any;
  screen: Screens;
  init: APIPreUser[];
  withNft: APIPreUser[];
  invited: APIPreUser[];
  users: APIUser[];
}

const Content = ({
  screen,
  loading,
  update,
  init,
  withNft,
  invited,
  users,
}: ContentProps) => {
  switch (screen) {
    case Screens.INIT:
      return <InitScreen loading={loading} users={init} update={update} />;
    case Screens.WITH_NFT:
      return (
        <WithNftScreen loading={loading} users={withNft} update={update} />
      );
    case Screens.INVITE_SENT:
      return <InvitedScreen loading={loading} users={invited} />;
    case Screens.RSVP:
      return <RsvpScreen loading={loading} users={users} />;
    case Screens.CREATE_USER:
      return <CreateUser update={update} />;
  }
  return null;
};
