import React from 'react';
import './style.scss';
import clsx from 'clsx';
import { Loader } from '../../../../general/loader';

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

export const GeneralScreen = ({ loading, children }: Props) => (
  <div className='all-users-wrapper'>
    <div className={clsx('loader-absolute-wrapper', loading && 'visible')}>
      <Loader size={120} thickness={15} color='#333' />
    </div>
    {children}
  </div>
);
