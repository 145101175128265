import React, { useState } from 'react';
import './style.scss';
import { Input } from '../../general/input';
import useAdminAuth from '../../../contexts/AdminAuthContext';

export const LoginScreen = () => {
  const { login } = useAdminAuth();
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    login(password).catch(() => {
      setPassword('');
    });
  };

  return (
    <div className='login-screen-wrapper'>
      <Input
        value={password}
        onChange={setPassword}
        type='password'
        onEnterPress={handleLogin}
        autoFocus
      />
    </div>
  );
};
