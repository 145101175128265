import React, { useMemo, useState } from 'react';
import './style.scss';
import { APIPreUser, NftStatus } from '../../../../../../shared/interfaces';
import { GeneralScreen } from '../general/screen';
import { Button } from '../../../general/button';
import { fetcher } from '../../../../utils/fetcher';
import { UserList } from '../general/userList';
import { transformAddress } from '../../../layout/header/walletAddress';
import { UserIndicator } from '../general/indicator';
import { UserScreenHeader } from '../general/header';
import useAlert from '../../../../contexts/alert';

interface Props {
  users: APIPreUser[];
  loading: boolean;
  update: () => any;
}

interface Separated {
  unminted: APIPreUser[];
  pending: APIPreUser[];
}

export const InitScreen = ({ loading, users, update }: Props) => {
  const alert = useAlert();
  const [mintLoading, setMintLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const separated = useMemo<Separated>(() => {
    return {
      unminted: users.filter((u) => u.nftStatus === NftStatus.NONE),
      pending: users.filter((u) => u.nftStatus === NftStatus.PENDING),
    };
  }, [users]);

  const handleMintClick = async () => {
    setMintLoading(true);
    const addresses = separated.unminted.map((u) => u.address);
    const stringified = JSON.stringify(addresses).replaceAll('"', '');
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(stringified)
    );
    element.setAttribute('download', 'addresses.txt');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setPending();
  };

  const setPending = () => {
    fetcher.admin
      .setPending()
      .then(() => {
        alert.show('Put those on waiting list');
        update();
      })
      .catch((err) => alert.showError(err))
      .finally(() => setMintLoading(false));
  };

  const handleUpdateClick = () => {
    setUpdateLoading(true);
    fetcher.admin
      .updateNftStatus()
      .then(() => update())
      .catch((err) => alert.showError(err))
      .finally(() => setUpdateLoading(false));
  };

  return (
    <GeneralScreen loading={loading}>
      <UserScreenHeader>
        <Button
          loading={mintLoading}
          onClick={handleMintClick}
          disabled={separated.unminted.length === 0}
        >
          Mint to new users
        </Button>
        <Button
          loading={updateLoading}
          onClick={handleUpdateClick}
          disabled={separated.pending.length === 0}
        >
          Update pending users
        </Button>
      </UserScreenHeader>
      <UserList>
        {users.map((user, index) => (
          <User data={user} key={index} />
        ))}
      </UserList>
    </GeneralScreen>
  );
};

interface UserProps {
  data: APIPreUser;
}

const User = ({ data }: UserProps) => {
  const transformedAddress = useMemo<string>(() => {
    return transformAddress(data.address, 6);
  }, [data.address]);

  return (
    <li className='user-init'>
      <div className='user-left'>
        <p>{transformedAddress}</p>
      </div>
      <div className='user-right'>
        <UserIndicator
          color={data.nftStatus === NftStatus.NONE ? 'empty' : 'yellow'}
        />
        <p className='user-status'>{data.nftStatus}</p>
      </div>
    </li>
  );
};
