import React from 'react';
import './style.scss';

interface Props {
  children: React.ReactNode;
}

export const UserScreenHeader = ({ children }: Props) => (
  <div className='general-screen-header'>{children}</div>
);
