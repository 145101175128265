import React, { useMemo, useState } from 'react';
import './style.scss';
import { APIUser } from '../../../../../../shared/interfaces';
import { GeneralScreen } from '../general/screen';
import { UserList } from '../general/userList';
import { transformAddress } from '../../../layout/header/walletAddress';
import { UserIndicator } from '../general/indicator';
import { fetcher } from '../../../../utils/fetcher';
import clsx from 'clsx';
import { Loader } from '../../../general/loader';
import useAlert from '../../../../contexts/alert';
import { ModalState, RsvpModal } from './modal';

interface Props {
  users: APIUser[];
  loading: boolean;
}

export const RsvpScreen = ({ loading, users }: Props) => {
  const alert = useAlert();
  const [modal, setModal] = useState<ModalState>({ opened: false });

  const openModal = (address: string, email: string): Promise<void> => {
    return new Promise((resolve) => {
      fetcher.admin
        .getRsvp(address)
        .then((response) => {
          setModal({
            opened: true,
            data: { ...response.rsvp, address, email },
          });
          resolve();
        })
        .catch((err) => {
          alert.showError(err);
          console.log(err);
          resolve();
        });
    });
  };

  return (
    <>
      <GeneralScreen loading={loading}>
        <UserList>
          {users.map((user, index) => (
            <User data={user} key={index} openModal={openModal} />
          ))}
        </UserList>
      </GeneralScreen>
      <RsvpModal state={modal} closeModal={() => setModal({ opened: false })} />
    </>
  );
};

interface UserProps {
  data: APIUser;
  openModal: (address: string, email: string) => Promise<void>;
}

const User = ({ data, openModal }: UserProps) => {
  const [loading, setLoading] = useState(false);

  const transformedAddress = useMemo<string>(() => {
    return transformAddress(data.address, 10);
  }, [data.address]);

  const blockClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUserClick = () => {
    setLoading(true);
    openModal(data.address, data.email)
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <li className='user-rsvp' onClick={handleUserClick}>
      <div className='user-left'>
        <p>{data.email}</p>
        <a
          href={`${process.env.OPENSEA_URL}${data.address}`}
          target='_blank'
          onClick={blockClick}
        >
          {transformedAddress}
        </a>
      </div>
      <div className='user-right'>
        <UserIndicator color='green' />
        <p className='user-status'>Attending</p>
      </div>
      <div className={clsx('loader-wrapper', loading && 'visible')}>
        <Loader size={20} thickness={3} color={'#486B5D'} />
      </div>
    </li>
  );
};
