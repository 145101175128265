import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  value: boolean;
  onClick: () => any;
}

const Switch = ({ value, onClick }: Props) => (
  <div className={clsx('switch', value && 'switch-active')} onClick={onClick}>
    <div className='switch-bullet' />
  </div>
);

export default Switch;
