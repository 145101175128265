import React from 'react';
import './index.scss';
import { createRoot } from 'react-dom/client';
import { AdminAuthContextProvider } from './contexts/AdminAuthContext';
import { AdminApp } from './AdminApp';
import { AlertContextProvider } from './contexts/alert';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AlertContextProvider>
    <AdminAuthContextProvider>
      <BrowserRouter>
        <AdminApp />
      </BrowserRouter>
    </AdminAuthContextProvider>
  </AlertContextProvider>
);
