import React, { useMemo, useState } from 'react';
import './style.scss';
import { APIPreUser, PreUserStatus } from '../../../../../../shared/interfaces';
import { GeneralScreen } from '../general/screen';
import { UserList } from '../general/userList';
import { transformAddress } from '../../../layout/header/walletAddress';
import { UserIndicator } from '../general/indicator';
import { UserScreenHeader } from '../general/header';
//import { fetcher } from '../../../../utils/fetcher';
//import useAlert from '../../../../contexts/alert';
import Switch from '../../../general/switch';

interface Props {
  users: APIPreUser[];
  loading: boolean;
}

export const InvitedScreen = ({ loading, users }: Props) => {
  //const alert = useAlert();
  //const [number, setNumber] = useState(8);
  //const [genLoading, setGenLoading] = useState(false);
  const [onlyReceived, setOnlyReceived] = useState(false);

  /*const handleChange = (newValue: string) => {
    const numeric = +newValue;
    if (!numeric || isNaN(numeric)) {
      return;
    }
    setNumber(numeric);
  };

  const regenerate = () => {
    setGenLoading(true);
    if (isNaN(number) || number <= 0) {
      return;
    }
    fetcher.admin
      .regenerateLinks(number)
      .then((response) => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(response.inviteLinks))
        );
        element.setAttribute('download', 'InviteLinks.json');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((err) => {
        alert.showError(err);
        console.log(err);
      })
      .finally(() => setGenLoading(false));
  };*/

  const finalUsersList = useMemo<APIPreUser[]>(() => {
    if (!onlyReceived) return users;
    return users.filter((u) => u.status === PreUserStatus.INVITE_RECEIVED);
  }, [users, onlyReceived]);

  return (
    <GeneralScreen loading={loading}>
      <UserScreenHeader>
        <Switch
          value={onlyReceived}
          onClick={() => setOnlyReceived((prev) => !prev)}
        />
        {/*<Input value={String(number)} onChange={handleChange} type='phone' />
        <Button loading={genLoading} onClick={regenerate}>
          Regenerate Invites
        </Button>*/}
      </UserScreenHeader>
      <UserList>
        {finalUsersList.map((user, index) => (
          <User data={user} key={index} index={index} />
        ))}
      </UserList>
    </GeneralScreen>
  );
};

interface UserProps {
  data: APIPreUser;
  index: number;
}

const User = ({ data, index }: UserProps) => {
  const transformedAddress = useMemo<string>(() => {
    return transformAddress(data.address, 32);
  }, [data.address]);

  return (
    <li className='user-init'>
      <div className='user-left-wrapper'>
        <h1 className='marker'>{index + 1}.</h1>
        <div className='user-left'>
          <a href={`${process.env.OPENSEA_URL}${data.address}`} target='_blank'>
            <p>{transformedAddress}</p>
          </a>
          <p className='user-left-nonce'>{data.nonce}</p>
        </div>
      </div>
      <div className='user-right'>
        <UserIndicator
          color={
            data.status === PreUserStatus.INVITE_RECEIVED ? 'green' : 'empty'
          }
        />
        <p className='user-status'>{data.status}</p>
      </div>
    </li>
  );
};
