import React, { useMemo } from 'react';
import './style.scss';
import clsx from 'clsx';
import useLanguage from '../../../../contexts/Language';

interface Props {
  address: string;
  className?: string;
  onClick?: (address: string) => any;
}

export const transformAddress = (input: string, num: number) => {
  if (!input || input.length < num - 1) return input;
  return `${input.substring(0, num)}...${input.substring(input.length - 3)}`;
};

export const WalletAddress = ({ address, className, onClick }: Props) => {
  const { language } = useLanguage();

  const transformed = useMemo<string>(() => {
    return transformAddress(address, 10);
  }, [address]);

  return (
    <div className={clsx('wallet-address', className)}>
      <div className='wallet-address-stuff'>
        <div className='wa-stuff-top' />
        <div className='wa-stuff-bottom' />
        <div className='wa-stuff-left' />
        <div className='wa-stuff-right' />
      </div>
      <p className='wallet-address-title'>{language.header.walletAddress}</p>
      <p className='wallet-address-value'>{transformed}</p>
    </div>
  );

  // TODO:: Don't forget to restore onClick (in menu)

  /*return (
    <a
      href={`${process.env.OPENSEA_URL}${address}`}
      target='_blank'
      className={clsx('wallet-address', className)}
      onClick={() => {
        if (onClick) onClick(address);
      }}
    >
      <div className='wallet-address-stuff'>
        <div className='wa-stuff-top' />
        <div className='wa-stuff-bottom' />
        <div className='wa-stuff-left' />
        <div className='wa-stuff-right' />
      </div>
      <p className='wallet-address-title'>{language.header.walletAddress}</p>
      <p className='wallet-address-value'>{transformed}</p>
    </a>
  );*/
};
