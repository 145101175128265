import React from 'react';
import { Loader } from './components/general/loader';
import { LoginScreen } from './components/admin/loginScreen';
import useAdminAuth from './contexts/AdminAuthContext';
import { AdminLoggedIn } from './components/admin/loggedIn';

export const AdminApp = () => {
  const { loggedIn, loading } = useAdminAuth();

  if (loading) {
    return <Loader size={100} thickness={10} color={'#486B5D'} />;
  }

  if (loggedIn) {
    return <AdminLoggedIn />;
  }

  return <LoginScreen />;
};
