import React from 'react';
import './style.scss';
import { RSVP } from '../../../../../../../shared/interfaces';

type ModalClosed = { opened: false };

type ModalOpened = {
  opened: true;
  data: RSVP & { address: string; email: string };
};

export type ModalState = ModalClosed | ModalOpened;

interface Props {
  state: ModalState;
  closeModal: () => void;
}

export const RsvpModal = ({ state, closeModal }: Props) => {
  if (!state.opened) return null;

  const blockClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const d = state.data;

  return (
    <div className='rsvp-modal-wrapper' onClick={() => closeModal()}>
      <div className='rsvp-modal' onClick={blockClick}>
        <div className='rsvp-modal-header'>
          <h1>{d.email}</h1>
          <h2>{d.address}</h2>
        </div>
        <div className='rsvp-modal-body'>
          <RsvpLine title='Salutation:' value={d.salutation} />
          <RsvpLine title='Surname:' value={d.lastName} />
          <RsvpLine title='Given Name:' value={d.firstName} />
          <RsvpLine title='Mobile No:' value={d.mobile} />
          <RsvpLine title='Current Car:' value={d.currentCar} />
          <RsvpLine title='Person Count:' value={String(d.personCount)} />
          <RsvpLine title='Dietary:' value={d.dietaryRestrictions} />
          <RsvpLine title='Remarks:' value={d.remarks} />
        </div>
      </div>
    </div>
  );
};

interface RsvpLineProps {
  title: string;
  value?: string;
}

const RsvpLine = ({ title, value }: RsvpLineProps) => (
  <div className='rsvp-mb-line'>
    <div className='rsvp-mb-title'>{title}</div>
    <div className='rsvp-mb-value'>
      {value && value.length > 0 ? value : '-'}
    </div>
  </div>
);
