import React, { useState } from 'react';
import './style.scss';
import { Input } from '../../../general/input';
import { fetcher } from '../../../../utils/fetcher';
import { Button } from '../../../general/button';

interface Props {
  update: () => any;
}

interface ValidData {
  number: string;
  valid: true;
}

interface InvalidData {
  number: string;
  valid: false;
  reason: string;
}

type Data = ValidData | InvalidData;

export const CreateUser = ({ update }: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data>({ number: '', valid: true });

  const handleChange = (value: string) => {
    setData((prev) => ({ ...prev, number: value }));
  };

  const addUsers = () => {
    if (isNaN(Number(data.number))) {
      return;
    }
    setLoading(true);
    fetcher.admin
      .createUsers(Number(data.number))
      .then(() => {
        setData({ valid: true, number: '' });
        update();
      })
      .catch((err) => {
        console.log(err);
        setData((prev) => ({
          valid: false,
          number: prev.number,
          reason: 'Smth is wrong',
        }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className='create-users-wrapper'>
      <Input
        value={data.number}
        onChange={(value) => handleChange(value)}
        onEnterPress={addUsers}
        placeholder='how many?'
        valid={data.valid}
        helperMessage={!data.valid ? data.reason : undefined}
        className='create-users-input'
        disabled={loading}
        type='phone'
      />
      <Button
        loading={loading}
        className='create-users-button'
        onClick={addUsers}
      >
        Create Users
      </Button>
    </div>
  );
};
